import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { serializeImage } from '../../utils/SerializeImage';
import BackgroundImage from '../BackgroundImage';
import Button from '../Button';
import { useTranslation } from 'next-i18next';

import styles from './Cards.module.scss'

const Cards = ({ heading, preamble, items, pageType }) => {
    const isSingle = items.length === 1;
    const shouldCenterCards = ["Article", "Landing"].includes(pageType);

    return (
        <div className={styles["Cards"]}>
            <div className={styles["Cards__Header"]}>
                {heading && <h2 className={styles["Cards__Heading"]}>{heading}</h2>}
                {preamble && (
                    <div
                        className={styles["Cards__Preamble"]}
                        dangerouslySetInnerHTML={{ __html: preamble }}
                    />
                )}
            </div>
            <div className={styles["Cards__Container"]}>
                {items.map((item, index) => (
                    <Card {...item} key={index} single={isSingle} isCentered={shouldCenterCards} />
                ))}
            </div>
        </div>
    );
};

Cards.propTypes = {
    heading: PropTypes.string,
    preamble: PropTypes.string,
    items: PropTypes.array,
};

Cards.defaultProps = {
    items: [],
};

const Card = ({
    buttonText,
    single,
    page: { isExternal, href, image, title, text },
    isCentered = false,
}) => {
    const { t } = useTranslation("common")
    const serializedImage = serializeImage(image);

    const classes = classNames(styles["Cards__Card"], {
        [styles["Cards__Card--Large"]]: single,
        [styles["Cards__Card--Half"]]: !single,
        [styles["Cards__Card--Centered"]]: isCentered,
    });

    const arrowType = isExternal ? 'DiagonalArrow' : 'Arrow';
    const buttonType = single ? 'Primary' : 'PrimarySmall';

    let imageProps = {
        sizes: '(max-width: 768px) 100vw, (max-width: 1280px) 80vw, 1260px',
    };

    if (!single) {
        imageProps.sizes =
            '(max-width: 768px) 100vw, (max-width: 1280px) 50vw, 618px';
        imageProps.quality = 70;
    }

    return (
        <article className={classes}>
            <div className={styles["Cards__Image"]}>
                {serializedImage && 
                    <BackgroundImage
                        {...imageProps}
                        {...serializedImage}
                    />}
            </div>
            <div className={styles["Cards__Content"]}>
                <h2 className={styles["Cards__Title"]}>{title}</h2>
                <div
                    className={styles["Cards__Text"]}
                    dangerouslySetInnerHTML={{ __html: text }}></div>
            </div>
            <div className={styles["Cards__Button"]}>
                <Button
                    link={href}
                    text={buttonText || t('cards.readMore')}
                    label={title}
                    icon={arrowType}
                    type={buttonType}
                />
            </div>
        </article>
    );
};

Card.propTypes = {
    href: PropTypes.string,
    image: PropTypes.object,
    title: PropTypes.string,
    text: PropTypes.string,
    single: PropTypes.bool,
    buttonText: PropTypes.string,
    page: PropTypes.object,
    isCentered: PropTypes.bool,
};

Card.defaultProps = {
    href: '',
    image: {},
    page: {},
    title: '',
    text: '',
    single: false,
    isCentered: false,
};

export default Cards;
